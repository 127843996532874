import React, {useState, useRef, useEffect} from 'react';
import GraphTest from "./GraphTest";
import Header from "../components/Header";

import {Web3} from "web3";
import ContractABI from "../abi/ContractABI"
import {Address} from "../ContractAdress";

import ContractABIToken from "../abi/ContractABIToken"
import {AddressTokenProject, AddressTokenUsdt} from "../ContractAdress";

import { useWeb3Modal } from '@web3modal/ethers/react'

import { useWeb3ModalProvider, useWeb3ModalAccount } from '@web3modal/ethers/react'
import { CopyToClipboard } from 'react-copy-to-clipboard';

import {useTranslation} from "react-i18next";

import exactMath from 'exact-math';

var web3;
var account;


const Account = ({handleOpen, handleClose}) => {
    const {t} = useTranslation();
    const [isAccordionOpen, setAccordionOpen] = useState(false);
    const [balanceToken, setBalanceToken] = useState(null);
    const [allDeposit, setAllDeposit] = useState(null);
    const [allSumDeposit, setAllSumDeposit] = useState(null);
    const [allUsers, setAllUsers] = useState(null);
    const [allActiveUsers, setActiveAllUsers] = useState(null);

    const [userRewards, setUserRewards] = useState(0);
    const [allRewards, setAllRewards] = useState(0);
    const [encodedRef, setEencodedRef] = useState("");
    const [shortAddress, setShortAddress] = useState("");

    const contentRef = useRef(null);

    const { address, chainId, isConnected } = useWeb3ModalAccount();
    const { walletProvider } = useWeb3ModalProvider();

    let pDecimals, uDecimals;

    const toggleAccordion = () => {
        setAccordionOpen(!isAccordionOpen);
    };

    const truncateRegex = /^(0x[a-zA-Z0-9]{6})[a-zA-Z0-9]+([a-zA-Z0-9]{6})$/;
    const truncateEthAddress = (address) => {
        const match = address.match(truncateRegex);
        if (!match) return address;
        return `${match[1]} . . . ${match[2]}`;
    };

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.style.maxHeight = isAccordionOpen ? `${contentRef.current.scrollHeight}px` : '0px';
        }

        setShortAddress(truncateEthAddress(address));

        GetBalance();
        GetAllDeposit();



    }, [isAccordionOpen, address]);


    useEffect(() => {

        const ref = `${process.env.REACT_APP_DOMAIN}/?invitetoken=${btoa(address)}`;

        setEencodedRef(ref);
        // decodedString = atob(encodedString);
    },[address]);


    const [textToCopy, setTextToCopy] = useState(''); // The text you want to copy
    const [copyStatus, setCopyStatus] = useState(false); // To indicate if the text was copied

    const onCopyText = () => {
        setCopyStatus(true);
        setTimeout(() => setCopyStatus(false), 2000); // Reset status after 2 seconds
    };


    const GetBalance =  async () => {

        let bToken;
        try {
            if (isConnected) {
                try {
                    // const accounts = await window.ethereum.request({
                    //     method: "eth_requestAccounts",
                    // });
                    // web3 = new Web3(window.ethereum)
                    web3 = new Web3(walletProvider)
                    account = address; //accounts[0];
                    // console.log(accounts[0]); //мой кошелек
                } catch (error) {
                    console.log("Error connecting...");
                }
            } else {
                console.log("Download Metamask");
            }

            //////////////////////////////////////////////////////////////

            let contractToken = new web3.eth.Contract(ContractABIToken, AddressTokenProject);

            pDecimals = parseInt(await contractToken.methods.decimals().call());
            bToken = exactMath.div(Number(await contractToken.methods.balanceOf(account).call()), 10 ** pDecimals);

        } catch (e) {
            console.error(`GetBalance error`);
        }


        setBalanceToken(bToken);

    }
    const GetAllDeposit = async () =>{
        let allDeposit = 0;
        let allSumDeposit = 0;
        let activeUsers = [];
        let allActiveUsers = [];

        try {
            if (isConnected) {
                try {
                    // const accounts = await window.ethereum.request({
                    //     method: "eth_requestAccounts",
                    // });
                    // web3 = new Web3(window.ethereum)
                    web3 = new Web3(walletProvider)
                    account = address; //accounts[0];
                    // console.log(accounts[0]); //мой кошелек
                } catch (error) {
                    console.log("Error connecting...");
                }
            } else {
                console.log("Download Metamask");
            }

            //////////////////////////////////////////////////////////////

            let contract1 = new web3.eth.Contract(ContractABI, Address);

            let contractTokenUSD = new web3.eth.Contract(ContractABIToken, AddressTokenUsdt);
            uDecimals = Number(await contractTokenUSD.methods.decimals().call());


            // contract1.events.Deposit({
            // fromBlock: 0
            // }, function (error, event) {
            // })
            //     .on('data', function (event) {
            //         allDeposit.push(1);
            //         setAllDeposit(allDeposit.length);
            //
            // })

            allDeposit = exactMath.div(Number(await contract1.methods.allDeposits().call()), 10 ** uDecimals);
            setAllDeposit(allDeposit);


            // let allRewards = parseInt(await contract1.methods.allRewards().call()) / 10 ** uDecimals;
            let allRewards = exactMath.div(Number(await contract1.methods.allRewards().call()), 10 ** uDecimals);
            setAllRewards(allRewards);

            // contract1.events.Deposit({
            // filter: {user:account},
            // fromBlock: 0
            // }, function (error, event) {
            // })
            //     .on('data', function (event) {
            //
            //         allSumDeposit += parseInt(event?.returnValues[1]) / 10 ** 18;
            //         setAllSumDeposit(allSumDeposit);
            //
            // })

            allSumDeposit = exactMath.div(Number(await contract1.methods.getUserDeposits(account).call()), 10 ** uDecimals);
            setAllSumDeposit(allSumDeposit);

            let userRewards = exactMath.div(Number(await contract1.methods.getUserRewards(account).call()), 10 ** uDecimals);
            setUserRewards(userRewards);


            //console.log("allSumDeposit", allSumDeposit, uDecimals);


            // contract1.events.AllActiveUsers({
            // fromBlock: 0
            // }, function (error, event) {
            // })
            //     .on('data', function (event) {
            //
            //         activeUsers.push(1);
            //         setAllUsers(activeUsers.length);
            //
            // })

            let allUsers = parseInt(await contract1.methods.getAllUsers().call());
            setAllUsers(allUsers);

            let transactions = [];

            // function getUniqueValues(arr) {
            //     const uniqueValues = new Set();
            //
            //     arr.forEach(item => {
            //         uniqueValues.add(item);
            //     });
            //
            //     return Array.from(uniqueValues);
            // }
            //
            //
            // contract1.events.Deposit({
            // filter: {datas:Date.now()},
            // fromBlock: 0
            // }, function (error, event) {
            // })
            //     .on('data', function (event) {
            //
            //         transactions = [...transactions,event?.returnValues[0]];
            //         allActiveUsers.push(1);
            //
            //         const uniqueNames = getUniqueValues(transactions, "address");
            //         console.log(uniqueNames);
            //
            //         setActiveAllUsers(uniqueNames.length);
            // })

            let activeUsers = parseInt(await contract1.methods.getActiveUsers().call());
            setActiveAllUsers(activeUsers);



        } catch (e) {
            console.error(`GetAllDeposit error`);
        }



        // setAllSumDeposit(1);
    }

    const { open } = useWeb3Modal()

    const truncate = (str, maxlength) => {
        return (str.length > maxlength) ?
            str.slice(0, maxlength - 1) + '…' : str;
    }

    return (
        <div
            className="lg:h-screen md:h-screen h-fit w-full bg-transparent text-white flex flex-col justify-start lg:gap-10 md:gap-8 gap-4 items-center relative lg:px-16 md:px-16 px-4">
            <Header handleOpen={handleOpen} handleClose={handleClose}/>
            <div
                id="referal"
                className="absolute lg:-top-28 lg:-left-20 -top-24 -left-50 bg-100 lg:w-full md:w-full sm:w-0 h-full rounded-lg -z-50"
                style={{
                    backgroundRepeat: 'no-repeat'// Ensures the background covers the div area
                }}
            >
            </div>
            <div className="w-full lg:h-screen md:h-screen h-fit flex flex-col lg:justify-center md:justify-center justify-start items-center lg:gap-16 md:gap-16 gap-6">
                <p className="text-white  lg:text-4xl md:text-4xl text-2xl lg:pt-0 md:pt-0 pt-6  font-semibold leading-tight text-left w-fit">
                    {t('cabinet')} <small>{shortAddress}</small></p>

                <p>
                    {allSumDeposit > 0 || false ? (
                    <p className=' text-xl font-normal'>{t('cabinet_reflink')}:
                        <b><small>{truncate(encodedRef, 35)}</small></b>
                        <CopyToClipboard text={encodedRef} onCopy={onCopyText}>
                            <button style={{float:'right', marginLeft: '15px'}} className={'w-[210px] flex items-center justify-center gap-4 rounded-lg border border-gray-600 bg-gradient-to-b from-mycolorfrom to-gray-mycolorto text-white text-sm font-medium leading-tight p-2 px-6'}>
                                {t('cabinet_reflink_copy')}
                            </button>
                        </CopyToClipboard>
                        {copyStatus && <p><small>{t('cabinet_reflink_copied')}</small></p>}
                    </p>
                    ) : (
                        <p className=' text-xl font-normal'>{t('cabinet_reflink_out')}</p>
                    )}

                </p>

                {chainId == process.env.REACT_APP_NETWORK ? ( <div className='flex flex-col lg:flex-row justify-center items-end w-full gap-8'>
                    <div
                        className='lg:w-1/2 w-full bg-[#222222] border border-gray-700 rounded-lg p-6 flex flex-col justify-center items-start'>
                        <p className=' text-2xl font-normal py-2'>{t('cabinet_balance')}: <b>{balanceToken}</b></p>
                        <p className=' text-2xl font-normal py-2'>{t('cabinet_deposit')}: <b>{allSumDeposit} USDT</b></p>
                        <p className=' text-2xl font-normal py-2'>{t('cabinet_ref')}: <b>{userRewards} USDT</b>
                        </p>
                        <div onClick={toggleAccordion}
                             className="cursor-pointer w-full text-2xl flex justify-between items-center py-2">
                            {t('cabinet_history')}
                            <svg xmlns="http://www.w3.org/2000/svg"
                                 className={`h-5 w-5 transform transition-transform duration-300 ${isAccordionOpen ? 'rotate-180' : 'rotate-0'}`}
                                 fill="none" viewBox="0 0 24 24" stroke="currentColor">
                                <path strokeLinecap="round" strokeLinejoin="round" strokeWidth={2} d="M19 9l-7 7-7-7"/>
                            </svg>
                        </div>
                        <div ref={contentRef}
                             className="transition-max-height duration-300 ease-in-out overflow-hidden">
                            <div className=' text-md font-normal pt-2'>
                                {t('cabinet_history_desc')}
                            </div>
                        </div>
                    </div>
                    <div className='lg:w-1/2 w-full flex flex-col justify-center items-start gap-6'>
                        <p className=' text-xl font-normal'>{t('cabinet_users')}: <b>{allUsers}</b></p>
                        <p className=' text-xl font-normal'>{t('cabinet_users_active')}: <b>{allActiveUsers}</b></p>
                        <p className=' text-xl font-normal'>{t('cabinet_deposits')}: <b>{allDeposit} USDT</b></p>
                        <p className=' text-xl font-normal'>{t('cabinet_refs')}: <b>{allRewards} USDT</b></p>


                    </div>
                </div>
                    ):(
                    <button className={'w-[210px] flex items-center justify-center gap-4 rounded-lg border border-gray-600 bg-gradient-to-b from-mycolorfrom to-gray-mycolorto text-white text-sm font-medium leading-tight p-2 px-6'} onClick={() => open({ view: 'Networks' })}>Need Network Change</button>
                )}
            </div>

            {chainId == process.env.REACT_APP_NETWORK && allSumDeposit > 0 ? (
            <GraphTest/>
                ): (
                    <div></div>
                )}

        </div>
    );
}

export default Account;
