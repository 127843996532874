import React, {useEffect, useState} from 'react';
import Predok from '../images/predok.svg';
import Me from '../images/me.svg';
import Me2 from '../images/referalLikeMe2.svg';
import Me2Empty from '../images/referalLikeMe2Empty.svg';
import Polygon from '../images/Polygon 12.svg';
import ReferalLikeMe from '../images/referalLikeMe.svg';
import MyReferal from '../images/myRefereal.svg';
import ReferalReferalEmpty from '../images/referalReferalEmpty.svg';
import MyEmptyReferal from '../images/myReferealEmpty.svg';
import ReferalLikeMeEmpty from '../images/referalLikeMeEmpty.svg';
import Slider from 'react-slick';
import ChevronDown from '../images/chevron-down.svg';
import Tooltip from "../components/Tooltip";
import {Web3} from "web3";
import ContractABI from "../abi/ContractABI"
import {Address, AddressTokenUsdt} from "../ContractAdress";
// import {parseInt} from "lodash";

import {useWeb3ModalProvider, useWeb3ModalAccount} from '@web3modal/ethers/react'
import ContractABIToken from "../abi/ContractABIToken";
import { useHistory } from "react-router-dom";


var window1;
var web3;
var account;


const SampleNextArrow = (props) => {
    const {onClick} = props;
    return (
        <div
            className="absolute right-0 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer"
            onClick={onClick}
        >
            <img src={ChevronDown} alt="Next" className="-rotate-90"/>
        </div>
    );
}

const SamplePrevArrow = (props) => {
    const {onClick} = props;
    return (
        <div
            className="absolute left-0 top-1/2 transform -translate-y-1/2 z-10 cursor-pointer"
            onClick={onClick}
        >
            <img src={ChevronDown} alt="Previous" className="rotate-90"/>
        </div>
    );
}

const GraphTest = ({deposit}) => {
    const [isLoading, setIsLoading] = useState(true);
    // const [activeWallet, setActiveWallet] = useState(undefined);
    // const [structure, setStructure] = useState({
    //     referal: {wallet: "", deposit: "", date: ""},
    //     referalsPrev: [],
    //     me: {wallet: "", deposit: "", date: ""},
    //     myReferals: []
    // });
    const [screenWidth, setScreenWidth] = useState(window.innerWidth);
    const [sliderCount, setSliderCount] = useState(1);

    const [currentLevel, setСurrentLevel] = useState(0);
    const [_currentAddress, setCurrent] = useState(null);
    const [currentAddress, setCurrentAddress] = useState({
        address: null,
        short: null,
        deposit: 0
    });
    const [upperAddress, setUpperAddress] = useState({
        address: null,
        short: null,
        deposit: 0
    });
    const [bottomAddress, setBottomAddress] = useState({
        refs: [],
        own: 0
    });

    const history = useHistory();

    let pDecimals, uDecimals;

    const handleResize = () => {

        const width = window.innerWidth * 1;
        console.log("Current width", width)
        setScreenWidth(width);

        if (width <= 500)
            setSliderCount(2);
        else if (width <= 750)
            setSliderCount(3);
        else if (width <= 1000)
            setSliderCount(4);
        else if (width <= 1250)
            setSliderCount(5);
        else if (width <= 1500)
            setSliderCount(6);
        else if (width <= 1750)
            setSliderCount(7);
        else
            setSliderCount(8);

    };

    const {address, chainId, isConnected} = useWeb3ModalAccount();
    const {walletProvider} = useWeb3ModalProvider();

    const truncateRegex = /^(0x[a-zA-Z0-9]{6})[a-zA-Z0-9]+([a-zA-Z0-9]{6})$/;
    const truncateEthAddress = (address) => {
        const match = address.match(truncateRegex);
        if (!match) return address;
        return `${match[1]} . . . ${match[2]}`;
    };

    const doIt = async () => {

        await ConnectWalletMetamask();

        ////////////////////////
        // await BuyReferral();
        ////////////////////////

        // return () => {
        //     window.removeEventListener('resize', handleResize);
        // };

    }

    useEffect(() => {
        window.addEventListener('resize', handleResize);
        handleResize();
    }, []);

    useEffect(() => {
        console.log("useEffect address", _currentAddress);
        doIt();
    }, [address]);

    useEffect(() => {
        console.log("useEffect _currentAddress", _currentAddress);
        if (_currentAddress)
            BuyReferral();
    }, [_currentAddress]);

    /////////////////////////////////////////////////////////
    const BuyReferral = async () => {

        try {
            setIsLoading(true);

            let contractTokenUSD = new web3.eth.Contract(ContractABIToken, AddressTokenUsdt);
            uDecimals = Number(await contractTokenUSD.methods.decimals().call());

            let contract = new web3.eth.Contract(ContractABI, Address);
            let currentDeposit = Number(await contract.methods.getUserDeposits(_currentAddress).call()) / 10 ** uDecimals;
            // console.log("BuyReferral", _currentAddress, truncateEthAddress(_currentAddress),  currentDeposit);
            setCurrentAddress({
                address: _currentAddress,
                short: truncateEthAddress(_currentAddress),
                deposit: currentDeposit
            });

            let upperAcc = await contract.methods.checkRefInviter(_currentAddress).call();
            let upperDep = Number(await contract.methods.getUserDeposits(upperAcc).call()) / 10 ** uDecimals;

            setUpperAddress({
                address: upperAcc,
                short: truncateEthAddress(upperAcc),
                deposit: upperDep
            });

            console.log("checkNumberAllRef", _currentAddress);
            let refs = await contract.methods.checkNumberAllRef(_currentAddress).call();
            console.log("checkNumberAllRef", refs);
            let allRefs = refs[0];
            let userRefs = refs[1];
            let ownRefs = Number(refs[2]);

            let refsInfo = [];

            if (Number(userRefs) > 0) {

                let refIDS = (await contract.methods.checkInviterAllRefs(_currentAddress, 1, userRefs).call())[1];
                console.log(refIDS);

                for (let refID of refIDS) {

                    let tmp = Number(await contract.methods.getUserDeposits(refID).call()) / 10 ** uDecimals;
                    refsInfo.push({
                        address: refID,
                        short: truncateEthAddress(refID),
                        deposit: tmp
                    })

                }
            }

            console.log("checkNumberAllRef", refsInfo, ownRefs);
            setBottomAddress({
                refs: refsInfo,
                own: ownRefs
            })

            setIsLoading(false);
        } catch (e) {
            console.log("checkNumberAllRef error", e);
            setIsLoading(false);
        }

    }
    /////////////////////////////////////////////////////////

    const settings = {
        className: "center",
        infinite: false,
        centerPadding: "30px",
        // slidesToShow: 4,
        swipeToSlide: true,
        nextArrow: <SampleNextArrow/>,
        prevArrow: <SamplePrevArrow/>,
        // afterChange: function (index) {
        //     console.log(
        //         `Slider Changed to: ${index + 1}, background: #222; color: #bada55`
        //     );
        // }
    };


    const ConnectWalletMetamask = async () => {
        // setIsLoading(true);
        // let NewStructure = {
        //     referal: {
        //         wallet: "",
        //         deposit: "",
        //         date: ""
        //     },
        //     referalsPrev: [],
        //     me: {
        //         wallet: "",
        //         deposit: "",
        //         date: ""
        //     },
        //     myReferals: []
        // };
        /////////////////////////////////////подключение к метамаску
        try {
            if (/*window.ethereum*/ isConnected) {
                try {
                    // const accounts = await window.ethereum.request({
                    //     method: "eth_requestAccounts",
                    // });
                    // web3 = new Web3(window.ethereum)
                    web3 = new Web3(walletProvider)
                    account = address; //accounts[0];
                    // setCurrentAddress(account);

                    // setStructure({
                    //     ...NewStructure,
                    //     me: {
                    //         ...NewStructure.me,
                    //         wallet: truncateEthAddress(account),
                    //         deposit: deposit,
                    //     }
                    // })
                    // NewStructure.me.wallet = truncateEthAddress(account);
                    // NewStructure.me.deposit = deposit;

                    let contractTokenUSD = new web3.eth.Contract(ContractABIToken, AddressTokenUsdt);
                    uDecimals = Number(await contractTokenUSD.methods.decimals().call());

                    //setTimeout(() => {
                    console.log("ConnectWalletMetamask level before", currentLevel);
                    setСurrentLevel(0);
                    setCurrent(account);
                    console.log("ConnectWalletMetamask level after", currentLevel);


                    //}, 200);

                    // setActiveWallet(activeWallet !== undefined ? activeWallet : account)
                } catch (error) {
                    console.log("Error connecting...");
                }
            } else {
                console.log("Download Metamask");
            }

            // ///////////////////////////////////////////////////////////////////////////////////////////////////
            // let contract1 = new web3.eth.Contract(ContractABI, Address);
            //
            // // //история транзакций моя
            // // const events = await contract1.getPastEvents('Deposit', {
            // //     filter: {user: NewStructure.me.wallet},
            // //     fromBlock: 0
            // // });
            // //
            // // if (events.length > 0) {
            // //     NewStructure.referal.wallet = events[0].returnValues[2]; // Принимаем первое событие для примера
            // // }
            // console.log(NewStructure.referal.wallet)
            // if (NewStructure.referal.wallet !== "") {
            //     NewStructure.referalsPrev = await getPrevRefferals(NewStructure.referal.wallet) // undefined
            // }
            // NewStructure.myReferals = await getMyRefferals(NewStructure.me.wallet)
            //
            // setTimeout(() => setStructure(NewStructure), 200);
            // // setIsLoading(false);
        } catch (e) {
            console.log(`Error: ConnectWalletMetamask`);
        }
    }

    /*
    const getPrevRefferals = async (adress_wallet) => {
        let temp = []
        try {
            if (isConnected) {
                try {
                    // const accounts = await window.ethereum.request({
                    //     method: "eth_requestAccounts",
                    // });
                    // web3 = new Web3(window.ethereum)
                    web3 = new Web3(walletProvider)
                    account = address; //accounts[0];
                } catch (error) {
                    console.log("Error connecting...");
                }
            } else {
                console.log("Download Metamask");
            }


            let contract1 = new web3.eth.Contract(ContractABI, Address);

            contract1.events.Deposit({
                filter: {inviter: `${adress_wallet}`},
                fromBlock: 0
            }, function (error, event) {
            })
                .on('data', function (event) {
                    temp.push({
                        wallet: event?.returnValues[0],
                        deposit: parseInt(event?.returnValues[1]) / 10 ** 18,
                        date: new Date(parseInt(event?.returnValues[3]) * 1000).toJSON()
                    });
                })

        } catch (e) {
            console.error('Prev');
        }
        return temp;
    }
    const getMyRefferals = async (adress_wallet) => {
        let temp = []
        try {
            if (isConnected) {
                try {
                    // const accounts = await window.ethereum.request({
                    //     method: "eth_requestAccounts",
                    // });
                    // web3 = new Web3(window.ethereum)
                    web3 = new Web3(walletProvider)
                    account = address; //accounts[0];
                    // console.log(accounts[0]); //мой кошелек
                } catch (error) {
                    console.log("Error connecting...");
                }
            } else {
                console.log("Download Metamask");
            }


            let contract1 = new web3.eth.Contract(ContractABI, Address);

            // //мои рефералы
            // contract1.events.Deposit({
            //     filter: {inviter: `${adress_wallet}`},
            //     fromBlock: 0
            // }, function (error, event) {
            // })
            //     .on('data', function (event) {
            //         temp.push({
            //             wallet: event?.returnValues[0],
            //             deposit: parseInt(event?.returnValues[1]) / 10 ** 18,
            //             date: new Date(parseInt(event?.returnValues[3]) * 1000).toJSON()
            //         });
            //     })


        } catch (e) {
            console.log(`Error2`);
        }
        return temp;
    }
    const getStructure = async (address_wallet) => {
        if (address_wallet !== undefined) {
            setIsLoading(true)
            let newStructure = {
                referal: {wallet: structure.me, deposit: "", date: ""},
                referalsPrev: [],
                me: {wallet: address_wallet, deposit: "", date: ""},
                myReferals: []
            };
            try {
                newStructure.referalsPrev = await getPrevRefferals(newStructure.referal.wallet)
                newStructure.myReferals = await getMyRefferals(address_wallet)
                setTimeout(() => setStructure(newStructure), 200);
                setIsLoading(false);
                console.log(structure)
            } catch (e) {
                console.log(`Error: getStructure`);
            }
        }

    }
    */

    const processUp = () => {
        if (currentAddress.address.toLowerCase() !== upperAddress.address.toLowerCase()) {
            console.log("processUp level before", currentLevel);
            setСurrentLevel(currentLevel - 1);
            setCurrent(upperAddress.address);
            console.log("processUp level after", currentLevel);
        }
    }

    const processDown = (addr) => {
        console.log("processDown level before", currentLevel);
        setСurrentLevel(currentLevel + 1);
        setCurrent(addr);
        console.log("processDown level after", currentLevel);
    }

    const buyOwn = () => {
        history.push("/selfbuy");
    }

    return (
        <div className={'w-full py-12'}>
            {isLoading ? (
                <div>Loading data from blockchain...</div> // Показать индикатор загрузки
            ) : (
                <div className={'flex flex-col'}>
                    { /*screenWidth < 768 || true &&*/ (
                        <div className={'flex flex-col justify-center items-center'}>
                            <div className={'flex flex-col justify-center items-center gap-4'}>
                                <Tooltip processUp={processUp} referal_link={upperAddress.short}
                                         deposit={upperAddress.deposit}
                                         level={currentLevel - 1}>
                                    <img className={'w-[125px] h-[160px]'} src={upperAddress.deposit > 0 ? Me2 : Predok}
                                         alt=""/>
                                </Tooltip>
                                <Tooltip referal_link={currentAddress.short} deposit={currentAddress.deposit}
                                         level={currentLevel}>
                                    <   img className={'w-[125px] h-[160px]'}
                                            src={currentAddress.deposit > 0 ? Me2 : Predok} alt={''}/>
                                </Tooltip>
                            </div>
                            <div className={'w-full pt-4 slider-container'}>
                                <Slider {...settings} slidesToShow={sliderCount}>
                                    {bottomAddress.refs.map((_, i) => (
                                        <div key={i} className="flex-none w-full">
                                            <div className={'flex flex-col justify-center items-center gap-4'}>
                                                <Tooltip
                                                    referal_link={bottomAddress.refs[i].short}
                                                    level={currentLevel + 1}
                                                    deposit={bottomAddress.refs[i].deposit}
                                                    processDown={processDown}
                                                    addr={bottomAddress.refs[i].address}
                                                >
                                                    <img src={Me2} alt=""/>
                                                </Tooltip>
                                                <img width={28} height={28} src={Polygon} alt={''}/>
                                            </div>
                                        </div>
                                    ))}
                                    {Array.from({length: bottomAddress.own > 0 ? bottomAddress.own : 0}).map((_, i) => (
                                        <div key={i} className="flex-none w-full">
                                            <div className={'flex flex-col justify-center items-center gap-4'}>
                                                <Tooltip
                                                    level={currentLevel + 1}
                                                    referal_link={`Own buyed`}
                                                    deposit={100}
                                                >
                                                    <img src={Me2} alt=""/>
                                                </Tooltip>
                                                <img width={28} height={28} src={Polygon} alt={''}/>
                                            </div>
                                        </div>
                                    ))}

                                    { currentLevel == 0 && address.toLowerCase() == _currentAddress.toLowerCase() && Array.from({length: 10 - bottomAddress.own - bottomAddress.refs.length > 0 ? 10 - bottomAddress.own - bottomAddress.refs.length : 0}).map((_, i) => (
                                        <div key={i} className="flex-none w-full">
                                            <div className={'flex flex-col justify-center items-center gap-4'}>
                                                <Tooltip
                                                    level={currentLevel + 1}
                                                    buyOwn={buyOwn}
                                                >
                                                    <img src={Me2Empty} alt=""/>
                                                </Tooltip>
                                                <img width={28} height={28} src={Polygon} alt={''}/>
                                            </div>
                                        </div>
                                    ))}

                                </Slider>
                            </div>
                        </div>
                    )}
                    {/*screenWidth < 768 && false && (
                        <>

                            <div className={'flex md:gap-8 lg:gap-2'}>
                                <div className={'flex flex-col justify-center items-center'}>
                                    <Tooltip processUp={processUp} referal_link={upperAddress.short} deposit={upperAddress.deposit}
                                             level={currentLevel - 1}>
                                        <img src={currentLevel == 1 ? Me: Predok} alt=""/>
                                    </Tooltip>
                                    <Tooltip referal_link={currentAddress.short} deposit={currentAddress.deposit} level={currentLevel}>
                                        <img src={currentLevel == 0 ? Me : Predok} alt={''}/>
                                    </Tooltip>
                                </div>
                                <div
                                    className={'flex md:flex-col lg:flex-row md:justify-start justify-end items-center gap-4'}>
                                    <div className={'flex gap-4 md:w-full lg:w-auto justify-start'}>

                                        {Array.from({length: 4}).map((_, i) => (
                                            <Tooltip
                                                referal_link={structure.referalsPrev[i] ? structure.referalsPrev[i].wallet : undefined}>
                                                <img key={i}
                                                     src={structure.referalsPrev[i] ? ReferalLikeMe : ReferalLikeMeEmpty}
                                                     alt={''}
                                                     onClick={() => getStructure(structure.referalsPrev[i] ? structure.referalsPrev[i].wallet : undefined)}/>
                                            </Tooltip>
                                        ))}

                                    </div>
                                    <div className={'flex gap-4'}>

                                        {Array.from({length: 5}).map((_, i) => (
                                            <Tooltip
                                                referal_link={structure.referalsPrev[i + 4] ? structure.referalsPrev[i + 4].wallet : undefined}>
                                                <img key={i}
                                                     src={structure.referalsPrev[i + 4] ? ReferalLikeMe : ReferalLikeMeEmpty}
                                                     alt={''}
                                                     onClick={() => getStructure(structure.referalsPrev[i] ? structure.referalsPrev[i].wallet : undefined)}/>
                                            </Tooltip>
                                        ))}

                                    </div>
                                </div>


                            </div>
                            <div className={'flex md:flex-col md:gap-8 lg:gap-0 lg:flex-row'}>
                                <div className={'flex md:pl-16'} >
                                    {bottomAddress.refs.map((_, i) => (
                                        <div key={i} className={'flex flex-col'}>
                                            <div>
                                                <Tooltip
                                                    referal_link={bottomAddress.refs[i].short}
                                                    level={currentLevel + 1}
                                                    deposit={bottomAddress.refs[i].deposit}
                                                    processDown={processDown}
                                                    addr={bottomAddress.refs[i].address}
                                                >
                                                    <img
                                                        src={structure.myReferals[i + 4] ? MyReferal : MyEmptyReferal}
                                                        alt={''}
                                                        onClick={() => getStructure(structure.myReferals[i] ? structure.myReferals[i].wallet : undefined)}/>
                                                </Tooltip>
                                            </div>
                                            <div className={'pl-20'}>
                                                <div className={'h-[36px] w-[55px]'}>
                                                    <img src={ReferalReferalEmpty} alt={''}/>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                                <div className={'flex md:pl-16 lg:pl-0'}>
                                    {Array.from({length: 10 - bottomAddress.own - bottomAddress.refs.length > 0? 10 - bottomAddress.own - bottomAddress.refs.length: 0}).map((_, i) => (
                                        <div key={i} className={'flex flex-col'}>
                                            <div>
                                                <Tooltip
                                                    referal_link={structure.myReferals[i] ? structure.myReferals[i].wallet : undefined}>
                                                    <img src={structure.myReferals[i] ? MyReferal : MyEmptyReferal}
                                                         alt={''}
                                                         onClick={() => getStructure(structure.myReferals[i] ? structure.myReferals[i].wallet : undefined)}/>
                                                </Tooltip>
                                            </div>
                                            <div className={'pl-20'}>
                                                <div className={'h-[36px] w-[55px]'}>
                                                    <img src={ReferalReferalEmpty} alt={''}/>
                                                </div>
                                            </div>
                                        </div>
                                    ))}
                                </div>
                            </div>


                        </>
                    )*/}
                </div>
            )}

        </div>
    );
}

export default GraphTest;
