import React, {useState, useRef, useEffect} from 'react';
import {useTranslation} from "react-i18next";

const Tooltip = ({children, price, deposit, level, date, referal_link, processUp, addr, processDown, buyOwn}) => {
    const {t} = useTranslation();
    const [isVisible, setIsVisible] = useState(false);
    const tooltipRef = useRef();

    const onMouseEnter = () => {
        setIsVisible(true);
    };

    const onMouseLeave = () => {
        setIsVisible(false);
    };

    const [labelText, setLabelText] = useState("");

    useEffect(() => {

        setLabelText(`L${level}: ${referal_link}, ${deposit} USDT`)

    }, [referal_link, deposit, level]);

    return (
        <div onClick={buyOwn? buyOwn: processUp? processUp: processDown? () => processDown(addr): () => {}} onMouseEnter={onMouseEnter} onMouseLeave={onMouseLeave} className="cursor-pointer" style={{position: 'relative'}}>
            {children}
            {isVisible && (
                <div ref={tooltipRef} style={{
                    position: 'absolute',
                    bottom: '50%',
                    left: '20%',
                    transform: 'translateX(-25%)',
                    marginBottom: '10px',
                    padding: '8px',
                    backgroundColor: '#333',
                    color: '#fff',
                    borderRadius: '6px',
                    zIndex: 1000,

                }}>
                    {referal_link ? <div className={'flex flex-col justify-center items-start'}>
                            {/*
                            ++ {deposit} ++
                            */}
                            <p style={{whiteSpace: 'nowrap'}} className={'font-bold'}>{labelText}</p>
                            <p className={'font-bold'}>{price}</p>
                            <p className={'text-gray-400'}>{date}</p>
                        </div> :
                        <div>
                            <button className={' flex items-center justify-center gap-4 w-[200px] rounded-lg border border-gray-600 bg-gradient-to-b from-mycolorfrom to-gray-mycolorto text-white  text-sm font-medium leading-tight p-2 px-6'}>
                                {t('cabinet_topup')}
                            </button>
                        </div>
                    }
                </div>
            )}
        </div>
    );
};

export default Tooltip
