import React, {useState} from 'react';
import Header from "../components/Header";
import {useTranslation} from "react-i18next";

import {Web3} from "web3";
import ContractABI from "../abi/ContractABI"
import {Address} from "../ContractAdress";

import ContractABIToken from "../abi/ContractABIToken"
import {AddressTokenUsdt} from "../ContractAdress";

import {useWeb3ModalProvider, useWeb3ModalAccount} from '@web3modal/ethers/react'

var web3;
var account;

const Deposit = ({handleOpen, handleClose}) => {
    const {t} = useTranslation();
    const [sum, setSum] = useState(null);

    const refLink = localStorage.getItem('refLink');
    const [refaddr, setAddress] = useState(refLink);

    const {address, chainId, isConnected} = useWeb3ModalAccount();
    const {walletProvider} = useWeb3ModalProvider();

    const [newErrors, setNewError] = useState(
        {
            sum: '',
            refaddr: '',
            valid: true
        }
    )

    const [curStatus, setCurStatus] = useState("");

    let uDecimals;

    const handleRegistrate = async () => {

        let valid = true;
        setNewError({
            sum: '',
            refaddr: '',
            valid: true
        })

        if (!sum || isNaN(sum) || sum < 100) {
            setNewError({
                    ...newErrors,
                    sum: t("error_amount"),
                    valid: false,
                }
            )
            //newErrors.sum = 'Сумма должна быть числом не менее 100';
            valid = false;
        }

        if (!refaddr) {
            setNewError({
                    ...newErrors,
                    refaddr: t("error_referral"),
                    valid: false,

                }
            )
            // newErrors.refaddr = 'Введите реферальную ссылку';
            valid = false;
        }


        if (valid) {
            try {
                if (isConnected) {
                    try {
                        // const accounts = await window.ethereum.request({
                        //     method: "eth_requestAccounts",
                        // });
                        // web3 = new Web3(window.ethereum)
                        web3 = new Web3(walletProvider)
                        account = address; //accounts[0];
                        // console.log(accounts[0]); //мой кошелек
                    } catch (error) {
                        console.log("Error connecting...");
                    }
                } else {
                    console.log("Download Metamask");
                }

                //////////////////////////////////////////////////////////////

                let contract1 = new web3.eth.Contract(ContractABI, Address);
                let contractToken = new web3.eth.Contract(ContractABIToken, AddressTokenUsdt);

                uDecimals = parseInt(await contractToken.methods.decimals().call());

                const approved =  await contractToken.methods.allowance(account, Address).call();
                if(approved < sum * 10 ** uDecimals) {
                    setCurStatus("USDT token approval....")
                    await contractToken.methods.approve(Address, sum * 10 ** uDecimals).send({from: account});
                }
                setCurStatus("Deposit processing....")
                await contract1.methods.deposit(atob(refaddr), sum).send({from: account});

                setCurStatus("");


            } catch (e) {
                console.error(`Deposit denied`);
                console.error(e);
                const err = e?.message ? e.message : "unknown error";
                setNewError({
                    ...newErrors,
                    sum: `Deposit Error: ${err}`,
                    valid: false,
                });
                setCurStatus("");
            }
        }

    }

    return (
        <div
            className="lg:h-screen md:h-screen h-fit w-full bg-transparent text-white flex flex-col justify-start lg:gap-4 md:gap-20 gap-4 items-center relative lg:px-16 md:px-16 px-4">
            <Header handleOpen={handleOpen} handleClose={handleClose}/>
            <div
                id="referal"
                className="absolute lg:-top-28 lg:-left-20 -top-24 -left-50 bg-100 lg:w-full md:w-full sm:w-0 h-full rounded-lg -z-50"
                style={{
                    backgroundRepeat: 'no-repeat'// Ensures the background covers the div area
                }}
            >
            </div>
            <div className="flex flex-col justify-center items-center lg:gap-16 md:gap-16 gap-8 w-full">
                <p className="text-white lg:pt-8 md:pt-8 pt-6 lg:text-3xl md:text-3xl text-2xl font-bold leading-snug text-left w-fit">{t("deposit")}</p>
                <div
                    className="bg-[#222222A8] p-6 lg:w-[616px] md:w-[616px] w-full rounded-lg border border-[#5E5E5E87] flex flex-col items-center gap-6">
                    <div className="flex flex-col items-center w-full gap-3">
                        <label
                            className="w-full text-left text-white text-xl font-normal leading-snug">{t("deposit_sum")}</label>
                        <input
                            type="number"
                            placeholder={t("deposit_sum_placeholder")}
                            className="w-full p-3 bg-[#282828] border border-[#686868] rounded text-white lg:text-xl md:text-xl text-lg font-bold leading-snug"
                            value={sum}
                            onChange={(e) => setSum(e.target.value)}
                        />
                    </div>
                    <div className="flex flex-col items-center w-full gap-3">
                        <label
                            className="w-full text-left text-white  text-xl font-normal leading-snug">{t("deposit_link")}</label>
                        <input
                            type="text"
                            disabled={true}
                            placeholder={t("referal_link_placeholder")}
                            className="w-full p-3 bg-[#282828] border border-[#686868] rounded text-white lg:text-xl md:text-xl text-lg font-bold leading-snug"
                            value={refaddr}
                            _onChange={(e) => setAddress(e.target.value)}
                        />
                    </div>
                    <button
                        className="h-16 w-full bg-gray-300 border border-gray-400 rounded-lg flex justify-center items-center text-black  text-xl font-bold leading-snug"
                        onClick={handleRegistrate}
                        disabled={curStatus? true: false}
                    >
                        {t("deposit_button")}

                    </button>
                    {!newErrors.valid && (
                    <p className="text-orange-700">
                        <b>
                            {newErrors.sum} {newErrors.refaddr}
                        </b>
                    </p>
                    )}
                    {curStatus && (
                        <p className="text-green-700">
                            <b>
                                {curStatus}
                            </b>
                        </p>
                    )}
                </div>
            </div>
        </div>
    );
}

export default Deposit;
