import React, {useState, useRef, useEffect} from 'react';
import Header from "../components/Header";

import {Web3} from "web3";
import ContractABI from "../abi/ContractABI"
import {Address} from "../ContractAdress";

import ContractABIToken from "../abi/ContractABIToken"
import ContractABIMulti from "../abi/ContractABIMulti"
import {AddressTokenProject, AddressTokenUsdt, AddressMulti} from "../ContractAdress";

import {useWeb3Modal} from '@web3modal/ethers/react'
import {useWeb3ModalProvider, useWeb3ModalAccount} from '@web3modal/ethers/react'

import {isMulti} from "../service/auth.service";
import {useTranslation} from "react-i18next";

var web3;
var account;


const Multisig = ({handleOpen, handleClose}) => {
    const {t} = useTranslation();
    const [isAccordionOpen, setAccordionOpen] = useState(false);
    const [balanceToken, setBalanceToken] = useState(null);
    const [allDeposit, setAllDeposit] = useState(null);
    const [allSumDeposit, setAllSumDeposit] = useState(null);
    const [allUsers, setAllUsers] = useState(null);
    const [allActiveUsers, setActiveAllUsers] = useState(null);

    const [userRewards, setUserRewards] = useState(0);
    const [allRewards, setAllRewards] = useState(0);
    const [encodedRef, setEencodedRef] = useState("");
    const [shortAddress, setShortAddress] = useState("");

    const contentRef = useRef(null);

    const {address, chainId, isConnected} = useWeb3ModalAccount();
    const {walletProvider} = useWeb3ModalProvider();

    let uDecimals;

    const toggleAccordion = () => {
        setAccordionOpen(!isAccordionOpen);
    };


    const [trxTo, setTrxTo] = useState("");
    const [trxAmount, setTrxAmount] = useState(0);

    const [unsignedTRX, setUnsignedTRX] = useState([]);
    const [signedTRX, setSignedTRX] = useState([]);
    const [requiredSignatures, setRequiredSignatures] = useState([]);

    const getTRX = async () => {
        try {

            if (isConnected) {

                web3 = new Web3(walletProvider)
                account = address;

                let contractTokenUSD = new web3.eth.Contract(ContractABIToken, AddressTokenUsdt);
                uDecimals = parseInt(await contractTokenUSD.methods.decimals().call());


                let contractMulti = new web3.eth.Contract(ContractABIMulti, AddressMulti);
                let _requiredSignatures = Number(await contractMulti.methods.requiredSignatures().call());
                console.log("_requiredSignatures", _requiredSignatures);
                setRequiredSignatures(_requiredSignatures);

                const _unexecuted = Number(await contractMulti.methods.getExecutedTransactionsQuantity(false).call());
                console.log("Total", _unexecuted);
                if (_unexecuted > 0) {

                    const ids = await contractMulti.methods.getTransactions(false, 1, _unexecuted).call();
                    console.log("IDS", ids);

                    const allTRX = [];
                    const unsig = [];
                    const sig = [];
                    for (let id of ids) {
                        const tr = await contractMulti.methods.transactions(id).call();

                        const _sign = await contractMulti.methods.confirmations(id, account).call();

                        allTRX.push(tr);
                        const _tr = {
                            id,
                            amount: Number(tr.amount) / 10 ** uDecimals,
                            to: tr.to,
                            numConfirmations: Number(tr.numConfirmations),
                            signed: _sign,
                        };
                        if (Number(tr.numConfirmations) >= _requiredSignatures) {
                            sig.push(_tr);
                        } else {
                            unsig.push(_tr);
                        }
                    }
                    console.log("allTRX", allTRX, "SIG", sig, "UNSIG", unsig);
                    setUnsignedTRX(unsig)
                    setSignedTRX(sig)

                } else {
                    throw new Error("Not connected");
                }

            } else {

                throw new Error("Not connected");

            }

        } catch (e) {

            console.log(e);
            setUnsignedTRX([])
            setSignedTRX([])

        }
    }

    useEffect(() => {
        getTRX();
    }, [address]);

    const [isMultiSig, setIsMultiSig] = useState(false);
    const [isSigner, setIsSigner] = useState(false);
    const [isManager, setIsManager] = useState(false);
    const [isFounder, setIsFounder] = useState(false);

    useEffect(() => {
        isMulti()
            .then((res) => {
                console.log(res);
                if (res.isMulti)
                    setIsMultiSig(true);
                else
                    setIsMultiSig(false);

                if (res.isFounder)
                    setIsFounder(true);
                else
                    setIsFounder(false);

                if (res.isManager)
                    setIsManager(true);
                else
                    setIsManager(false);

                if (res.isSigner)
                    setIsSigner(true);
                else
                    setIsSigner(false);

            })
            .catch((e) => {
                console.log("Error", e);
                setIsMultiSig(false);
                setIsManager(false);
                setIsFounder(false);
                setIsSigner(false);
            })
    }, [address]);

    const truncateRegex = /^(0x[a-zA-Z0-9]{6})[a-zA-Z0-9]+([a-zA-Z0-9]{6})$/;
    const truncateEthAddress = (address) => {
        const match = address.match(truncateRegex);
        if (!match) return address;
        return `${match[1]} . . . ${match[2]}`;
    };

    useEffect(() => {
        if (contentRef.current) {
            contentRef.current.style.maxHeight = isAccordionOpen ? `${contentRef.current.scrollHeight}px` : '0px';
        }

        setShortAddress(truncateEthAddress(address));


    }, [isAccordionOpen, address]);


    const [textToCopy, setTextToCopy] = useState(''); // The text you want to copy
    const [copyStatus, setCopyStatus] = useState(false); // To indicate if the text was copied

    const onCopyText = () => {
        setCopyStatus(true);
        setTimeout(() => setCopyStatus(false), 2000); // Reset status after 2 seconds
    };

    const {open} = useWeb3Modal()

    const truncate = (str, maxlength) => {
        return (str.length > maxlength) ?
            str.slice(0, maxlength - 1) + '…' : str;
    }

    const placeTRX = async () => {

        let valid = true;

        if (!trxAmount || isNaN(trxAmount) || !(Number(trxAmount) > 0 )) {
            valid = false;
        }

        if (!trxTo) {
            valid = false;
        }

        if (valid) {
            try {

                let contractTokenUSD = new web3.eth.Contract(ContractABIToken, AddressTokenUsdt);
                uDecimals = parseInt(await contractTokenUSD.methods.decimals().call());

                let contractMulti = new web3.eth.Contract(ContractABIMulti, AddressMulti);
                await contractMulti.methods.proposeTransaction(trxTo, Number(trxAmount) * 10 ** uDecimals).send({from: account});
                setTimeout(()=>{
                    getTRX();
                }, 5000)

            } catch (e) {
                console.error(`placeTRX error`, e);
            }
        }

    }

    const signTRX = async (id) => {

        try {
            let contractMulti = new web3.eth.Contract(ContractABIMulti, AddressMulti);
            await contractMulti.methods.confirmTransaction(id).send({from : address});
            setTimeout(()=>{
                getTRX();
            }, 5000)
        }catch(e){
            console.log("signTRX ERROR", e);
        }
    }

    const sendTRX = async (id) => {

        try {
            let contractMulti = new web3.eth.Contract(ContractABIMulti, AddressMulti);
            await contractMulti.methods.executeTransaction(id).send({from : address});
            setTimeout(()=>{
                getTRX();
            }, 5000)
        }catch(e){
            console.log("signTRX ERROR", e);
        }

    }

    return (
        <div
            className="lg:h-screen md:h-screen h-fit w-full bg-transparent text-white flex flex-col justify-start lg:gap-10 md:gap-8 gap-4 items-center relative lg:px-16 md:px-16 px-4">
            <Header handleOpen={handleOpen} handleClose={handleClose}/>
            <div
                id="referal"
                className="absolute lg:-top-28 lg:-left-20 -top-24 -left-50 bg-100 lg:w-full md:w-full sm:w-0 h-full rounded-lg -z-50"
                style={{
                    backgroundRepeat: 'no-repeat'// Ensures the background covers the div area
                }}
            >
            </div>
            <div
                className="w-full lg:h-screen md:h-screen h-fit flex flex-col lg:justify-center md:justify-center justify-start items-center lg:gap-16 md:gap-16 gap-6">
                <p className="text-white  lg:text-4xl md:text-4xl text-2xl lg:pt-0 md:pt-0 pt-6  font-semibold leading-tight text-left w-fit">
                    {t('multisig')}</p>

                {chainId == process.env.REACT_APP_NETWORK ? (
                    <>
                        {isFounder && signedTRX.length > 0 && (
                            <div className='flex flex-col lg:flex-row justify-center items-end gap-8'>
                                <div
                                    className='lg:w-full w-full bg-[#222222] border border-gray-700 rounded-lg p-6 flex flex-col justify-center items-start'>
                                    <p className=' text-2xl font-normal py-2'>
                                        Signed transactions for execution ( {signedTRX.length} pcs )</p>

                                    <div class="flex items-center justify-center">
                                        <table className='border-separate border-spacing-y-2 text-sm'>
                                            <thead class="sr-only">
                                            <tr>
                                                <th>To</th>
                                                <th>Amount</th>
                                                <th>Signed</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            {signedTRX.map((_, i) => (
                                                <tbody class="flex-1 sm:flex-none">
                                                <tr className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
                                                    key={`signed_${i}`}>
                                                    <td className='pr-7'>
                                                        {truncateEthAddress(signedTRX[i].to)}
                                                    </td>
                                                    <td className='pr-7'>
                                                        {signedTRX[i].amount} USDT
                                                    </td>
                                                    <td className='pr-7'>
                                                        {signedTRX[i].numConfirmations} / {requiredSignatures}
                                                    </td>
                                                    <td className='pr-7'>
                                                        <button
                                                            className={'w-[210px] flex items-center justify-center gap-4 rounded-lg border border-gray-600 bg-gradient-to-b from-mycolorfrom to-gray-mycolorto text-white text-sm font-medium leading-tight p-2 px-6'}
                                                            onClick={() => {sendTRX(signedTRX[i].id)}}
                                                        >
                                                            Send transaction
                                                        </button>
                                                    </td>
                                                </tr>
                                                </tbody>
                                            ))}
                                        </table>
                                    </div>

                                </div>


                            </div>
                        )}
                        {isManager && (<div className='flex flex-col lg:flex-row justify-center items-end gap-8'>

                                <div className='lg:w-full w-full flex flex-col justify-center items-start gap-6'>

                                    <p className=' text-xl font-normal'>New transaction</p>
                                    <div class="flex items-center justify-center">
                                        <table className='border-separate border-spacing-y-2 text-sm'>
                                            <thead class="sr-only">
                                            <tr>
                                                <th></th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            <tbody class="flex-1 sm:flex-none">
                                            <tr className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
                                            >
                                                <td className='pr-7'>To:</td>
                                                <td className='pr-7'>
                                                    <input
                                                        type="text"
                                                        placeholder={"Receiver address"}
                                                        className="w-full p-3 bg-[#282828] border border-[#686868] rounded text-white lg:text-xl md:text-xl text-lg font-bold leading-snug"
                                                        value={trxTo}
                                                        onChange={(e) => setTrxTo(e.target.value)}
                                                    />
                                                </td>

                                            </tr>
                                            <tr className="flex flex-col flex-no wrap sm:table-row mb-4 sm:mb-0">
                                                <td className='pr-7'>Amount:</td>
                                                <td className='pr-7'><input
                                                    type="number"
                                                    placeholder={"Amount"}
                                                    className="w-full p-3 bg-[#282828] border border-[#686868] rounded text-white lg:text-xl md:text-xl text-lg font-bold leading-snug"
                                                    value={trxAmount}
                                                    onChange={(e) => setTrxAmount(e.target.value)}
                                                />
                                                </td>
                                            </tr>
                                            <tr className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0">
                                                <td className='pr-7' colspan={2}>
                                                    <button
                                                        className={'w-full flex items-center justify-center gap-4 rounded-lg border border-gray-600 bg-gradient-to-b from-mycolorfrom to-gray-mycolorto text-white text-sm font-medium leading-tight p-2 px-6'}
                                                        onClick={placeTRX}
                                                    >
                                                        Place transaction
                                                    </button>
                                                </td>
                                            </tr>
                                            </tbody>
                                        </table>
                                    </div>

                                </div>
                            </div>
                        )}
                        {isSigner && unsignedTRX.length > 0 && (
                            <div className='flex flex-col lg:flex-row justify-center items-end gap-8'>
                                <div
                                    className='lg:w-full w-full bg-[#222222] border border-gray-700 rounded-lg p-6 flex flex-col justify-center items-start'>
                                    <p className=' text-2xl font-normal py-2'>
                                        {t('multisig_unsigned')} ( {unsignedTRX.length} {t('multisig_unsigned_pcs')} )</p>

                                    <div class="flex items-center justify-center">
                                        <table className='border-separate border-spacing-y-2 text-sm'>
                                            <thead class="sr-only">
                                            <tr>
                                                <th>To</th>
                                                <th>Amount</th>
                                                <th>Signed</th>
                                                <th></th>
                                            </tr>
                                            </thead>
                                            {unsignedTRX.map((_, i) => (
                                                <tbody class="flex-1 sm:flex-none">
                                                <tr className="flex flex-col flex-no wrap sm:table-row mb-2 sm:mb-0"
                                                    key={`signed_${i}`}>
                                                    <td className='pr-7'>
                                                        {truncateEthAddress(unsignedTRX[i].to)}
                                                    </td>
                                                    <td className='pr-7'>
                                                        {unsignedTRX[i].amount} USDT
                                                    </td>
                                                    <td className='pr-7'>
                                                        {unsignedTRX[i].numConfirmations} / {requiredSignatures}
                                                    </td>
                                                    <td className='pr-7'>
                                                        { !unsignedTRX[i].signed ? (<button
                                                            className={'w-[210px] flex items-center justify-center gap-4 rounded-lg border border-gray-600 bg-gradient-to-b from-mycolorfrom to-gray-mycolorto text-white text-sm font-medium leading-tight p-2 px-6'}
                                                            onClick={() => {signTRX(unsignedTRX[i].id)}}
                                                        >
                                                                {t('multisig_unsigned_sign')}
                                                        </button>) : (
                                                         <p> {t('multisig_unsigned_signed')} </p>
                                                        )}
                                                    </td>
                                                </tr>
                                                </tbody>
                                            ))}
                                        </table>
                                    </div>

                                </div>


                            </div>
                        )}
                    </>
                ) : (
                    <button
                        className={'w-[210px] flex items-center justify-center gap-4 rounded-lg border border-gray-600 bg-gradient-to-b from-mycolorfrom to-gray-mycolorto text-white text-sm font-medium leading-tight p-2 px-6'}
                        onClick={() => open({view: 'Networks'})}>Need Network Change</button>
                )}
            </div>

        </div>
    );
}

export default Multisig;
