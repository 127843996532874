import React, {useEffect, useState} from 'react';
import { Route, Redirect } from 'react-router-dom';
import {isMulti} from "../service/auth.service";
import {useWeb3ModalAccount} from '@web3modal/ethers/react'

const MultisigRoute = ({ children, ...rest }) => {

    const {address, chainId, isConnected} = useWeb3ModalAccount();
    const [isMultiSig, setIsMultiSig] = useState(false);

    useEffect(() => {
        isMulti()
            .then((res) => {
                console.log(res);
                if(res.isMulti)
                    setIsMultiSig(true);
                else
                    setIsMultiSig(false);
            })
            .catch((e)=> {
                console.log("Error", e);
                setIsMultiSig(false);
            })
    }, [address]);

    return (
        <Route
            {...rest}
            render={props =>
                isMultiSig ? (
                    children
                ) : (
                    <></>
                )
            }
        />
    );
};


export default MultisigRoute;
